import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CreatorOnboardingService } from '../../creator-onboarding.service';
import { UnderscoreToSpacePipe } from 'src/app/pipes/underscore-to-space.pipe';

@Component({
  selector: 'app-select-style',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    IconsComponent,
    TranslocoModule,
    UnderscoreToSpacePipe
  ],
  templateUrl: './select-style.component.html',
  styleUrl: './select-style.component.scss',
})
export class SelectStyleComponent implements OnInit, OnChanges {
  @Input() iconName = 'hear_style';
  @Input() selected?: string[] | string;
  @Input() header = 'What_your_hair_color';
  @Input() title: string = 'non';
  @Input({ required: true }) isArray: boolean = false;
  @Input({ required: true }) translate: boolean = false;
  @Input() data: string[] = [];
  @Input() isFunFilter: boolean = false;
  @Input() className: string = '';
  @Input() allowMultipleSelections: boolean = false;
  @Output() onSelectValue: EventEmitter<{ [key: string]: string[] | string }> = new EventEmitter();

  selectedIndexes: number[] = [];

  constructor(private onboarding: CreatorOnboardingService) { }

  ngOnInit(): void {

   }

  ngOnChanges(changes: SimpleChanges): void {
    this.onboarding.isNextStepAvailable.next(false);
    if (Array.isArray(this.selected) && this.data) {
      this.selectedIndexes = [];
      let y = [...this.selected]
      y.forEach(selectedItem => {
        this.data.forEach((item, index) => {
          if (item === selectedItem) {
            this.selectButton(index, item, false);
            this.onboarding.isNextStepAvailable.next(true);
          }
        });
      });
    }
    else if (this.selected && this.data){
      let r = String(this.selected)
      this.data.forEach((item, index) => {
                if (item.toLowerCase() === r.toLowerCase()) {
                  this.selectButton(index, item);
                  this.onboarding.isNextStepAvailable.next(true);
                }
              });
    }
  }

  public selectButton(index: number, value: string, emit = true) {
    if (this.allowMultipleSelections) {
      if (this.selectedIndexes.includes(index)) {
        this.selectedIndexes = this.selectedIndexes.filter(i => i !== index);
      } else {
        this.selectedIndexes.push(index);
      }
    } else {
      this.selectedIndexes = [index];
    }

    if (emit) {
      const selectedValues: string[] | string = this.isArray
        ? this.selectedIndexes.map(i => this.data[i])
        : this.data[this.selectedIndexes.at(0)];
      this.onSelectValue.emit({ [this.title]: selectedValues });
      this.onboarding.isNextStepAvailable.next(true);
    }
  }
}
