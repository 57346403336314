<div class="file-filter no-scrollbar" *transloco="let t;">
  <button *ngFor="let item of filterItems"
          type="button"
          class="file-filter__item action-text"
          (click)="setFilterString(item)"
          [ngClass]="{ 'active': filter === item.value }">
    {{ t(item.value) }} <span class="counter">{{ item.count }}</span>
  </button>
</div>

