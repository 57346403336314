"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESkinTone = void 0;
var ESkinTone;
(function (ESkinTone) {
    ESkinTone["FAIR"] = "FAIR";
    ESkinTone["LIGHT"] = "LIGHT";
    ESkinTone["MEDIUM"] = "MEDIUM";
    ESkinTone["OLIVE"] = "OLIVE";
    ESkinTone["TAN"] = "TAN";
    ESkinTone["BROWN"] = "BROWN";
    ESkinTone["DARK"] = "DARK";
})(ESkinTone || (exports.ESkinTone = ESkinTone = {}));
