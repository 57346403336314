import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterButtonsComponent, FilterItem } from '../filter-buttons/filter-buttons.component';
import { SelectableItem, SelectButtonsComponent } from '../select-buttons/select-buttons.component';
import {TranslocoModule} from "@ngneat/transloco";
import { NgClass, NgIf } from '@angular/common';
import { Subscription } from 'rxjs';

export enum  ICreatorTypeContent {
  "SFW" = "SFW",
  "NSFW" = "NSFW"
}

@Component({
  selector: 'app-creator-content-type',
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    FilterButtonsComponent,
    SelectButtonsComponent,
    TranslocoModule
  ],
  templateUrl: './creator-content-type.component.html',
  styleUrl: './creator-content-type.component.scss'
})
export class CreatorContentTypeComponent implements OnInit, OnChanges, OnDestroy{

  @Input() public isFunFilter: boolean = false;
  @Input() public contentCollect: SelectableItem[];
  @Output() public contentType: EventEmitter<any> = new EventEmitter();
  @Input() public resetEvent: any;
  private subscription: Subscription;
  public sortedAnswersCollect = [];
  public filterData;

  ngOnInit() {
    console.log(this.resetEvent);
    if(this.resetEvent) {
      console.log(this.resetEvent);

      this.subscription = this.resetEvent.subscribe(contentType => {
        this.contentCollect.forEach(item => item.isSelected = false);
        this.onSelectItemHandler()
        console.log('this.contentCollect', this.contentCollect);

      });
    }

  }

  ngOnChanges(): void {
      this.sortAnswer();
      this.filterData = [
        { value: ICreatorTypeContent.SFW, count: Object.keys(this.contentCollect).length, categoryName: ICreatorTypeContent.SFW },
        { value: ICreatorTypeContent.NSFW, count: Object.keys(this.contentCollect).length, categoryName: ICreatorTypeContent.NSFW },
      ];
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public selectedFilter(item: FilterItem) {
    this.sortAnswer(item.categoryName);
  }

  public onSelectItemHandler() {
    const categorizedContent: Record<string, string[]> = {
      [ICreatorTypeContent.SFW.toLowerCase()]: [],
      [ICreatorTypeContent.NSFW.toLowerCase()]: []
    };

    this.contentCollect.forEach(item => {
      if (item.isSelected) {
        const key = (item.categoryName as ICreatorTypeContent).toLowerCase();
        if (categorizedContent[key]) {
          categorizedContent[key].push(item.label.toUpperCase());
        }
      }
    });
    this.contentType.emit(categorizedContent);
  }

  private sortAnswer(categoryName: string = 'SFW') {
    this.sortedAnswersCollect = this.contentCollect.filter(item => item.categoryName === categoryName);
  }

  get selectedItemsAmount(): number {
    return this.contentCollect.filter(item => item.isSelected).length;
  }

}
