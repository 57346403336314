<ng-container *transloco="let t;">
  <div class="wrapper {{className}}">
      <ng-container *ngIf="!isFunFilter">
        <app-icons
          [name]="iconName"
          height="100"
          width="100"
          [fillColor]="'#6A49FA'"
        ></app-icons>
        <h3>{{ translate ? t(header) : header }}</h3>
    </ng-container>

    <div class="variants">
      <button
        *ngFor="let item of data; let i = index"
        [class.selected]="selectedIndexes.includes(i)"
        (click)="selectButton(i, item)"
      >
        {{ translate ? t(header + '_' + item) : item }}
      </button>
    </div>
  </div>
</ng-container>
