"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EBodyTypes = void 0;
var EBodyTypes;
(function (EBodyTypes) {
    EBodyTypes["SLIM"] = "SLIM";
    EBodyTypes["MUSCULAR"] = "MUSCULAR";
    EBodyTypes["AVERAGE"] = "AVERAGE";
    EBodyTypes["CURVY"] = "CURVY";
    EBodyTypes["FULL_FIGURED"] = "FULL_FIGURED";
    EBodyTypes["PETITE"] = "PETITE";
})(EBodyTypes || (exports.EBodyTypes = EBodyTypes = {}));
