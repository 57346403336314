"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyTypeOnboarding = void 0;
var MyTypeOnboarding;
(function (MyTypeOnboarding) {
    MyTypeOnboarding["SEXUALLY"] = "SEXUALLY";
    MyTypeOnboarding["INTIMACY"] = "INTIMACY";
    MyTypeOnboarding["CONFIDENCE"] = "CONFIDENCE";
    MyTypeOnboarding["HAIRSTYLE"] = "HAIRSTYLE";
    MyTypeOnboarding["FEATURE"] = "FEATURE";
    MyTypeOnboarding["TATTOOS"] = "TATTOOS";
    MyTypeOnboarding["LINGERIE"] = "LINGERIE";
    MyTypeOnboarding["DOMINANT_SUBMISSIVE"] = "DOMINANT_SUBMISSIVE";
    MyTypeOnboarding["EXPERIENCE"] = "EXPERIENCE";
    MyTypeOnboarding["TRAITS"] = "TRAITS";
    MyTypeOnboarding["EXPERIENCED"] = "EXPERIENCED";
    MyTypeOnboarding["EXPRESSIVE_RESERVED"] = "EXPRESSIVE_RESERVED";
    MyTypeOnboarding["FETISHES"] = "FETISHES";
    MyTypeOnboarding["ENERGY_STAMINA"] = "ENERGY_STAMINA";
    MyTypeOnboarding["CONTROL"] = "CONTROL";
    MyTypeOnboarding["ADVENTUROUS"] = "ADVENTUROUS";
    MyTypeOnboarding["ROLEPLAY"] = "ROLEPLAY";
    MyTypeOnboarding["GENTLE"] = "GENTLE";
    MyTypeOnboarding["DEALBREAKER"] = "DEALBREAKER"; // What’s a sexual dealbreaker for you in a partner?
})(MyTypeOnboarding || (exports.MyTypeOnboarding = MyTypeOnboarding = {}));
