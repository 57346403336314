"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHairLength = void 0;
var EHairLength;
(function (EHairLength) {
    EHairLength["BALD"] = "BALD";
    EHairLength["BUZZ CUT"] = "BUZZ CUT";
    EHairLength["SHORT"] = "SHORT";
    EHairLength["MEDIUM"] = "MEDIUM";
    EHairLength["LONG"] = "LONG";
    EHairLength["EXTRA LONG"] = "EXTRA LONG";
})(EHairLength || (exports.EHairLength = EHairLength = {}));
