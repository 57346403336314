<ng-container *transloco="let t;">
  <span *ngIf="!isFunFilter" class="extra-large header-wrapper">
    {{t('Your_content')}}
  </span>
  <app-filter-buttons *ngIf="!isFunFilter" [filterItems]="filterData" (selectedFilter)="selectedFilter($event)"></app-filter-buttons>
  <app-select-buttons
    [ngClass]="{'compact': isFunFilter}"
    [isFunFilter]="isFunFilter"
    [items]="sortedAnswersCollect"
    [selectedItemsAmount]="selectedItemsAmount"
    (onSelectItemHandler)="onSelectItemHandler($event)"
  ></app-select-buttons>
</ng-container>
