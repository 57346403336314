"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHairStyle = void 0;
var EHairStyle;
(function (EHairStyle) {
    EHairStyle["STRAIGHT"] = "STRAIGHT";
    EHairStyle["WAVY"] = "WAVY";
    EHairStyle["CURLY"] = "CURLY";
    EHairStyle["COILED_KINKY"] = "COILED_KINKY";
    EHairStyle["PIXIE"] = "PIXIE";
    EHairStyle["CUT_BOB"] = "CUT_BOB";
    EHairStyle["CUT_LOB"] = "CUT_LOB";
    EHairStyle["LAYERED"] = "LAYERED";
    EHairStyle["SHAG"] = "SHAG";
    EHairStyle["BANGS_FRINGE"] = "BANGS_FRINGE";
    EHairStyle["PONYTAIL"] = "PONYTAIL";
    EHairStyle["BUN"] = "BUN";
    EHairStyle["BRAIDS"] = "BRAIDS";
    EHairStyle["CORNROWS"] = "CORNROWS";
    EHairStyle["DREADLOCKS"] = "DREADLOCKS";
    EHairStyle["MOHAWK"] = "MOHAWK";
    EHairStyle["UNDERCUT"] = "UNDERCUT";
    EHairStyle["FADE"] = "FADE";
    EHairStyle["UPDO"] = "UPDO";
})(EHairStyle || (exports.EHairStyle = EHairStyle = {}));
