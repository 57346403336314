"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESfw = void 0;
var ESfw;
(function (ESfw) {
    ESfw["COSPLAY"] = "COSPLAY";
    ESfw["ASMR"] = "ASMR";
    ESfw["SPORTS"] = "SPORTS";
    ESfw["VLOGS"] = "VLOGS";
    ESfw["DANCING"] = "DANCING";
    ESfw["COOKING_SHOWS"] = "COOKING_SHOWS";
    ESfw["FEET"] = "FEET";
    ESfw["GAMING"] = "GAMING";
    ESfw["HORSES"] = "HORSES";
    ESfw["RACING"] = "RACING";
})(ESfw || (exports.ESfw = ESfw = {}));
