import { Injectable } from '@angular/core';
import { SelectableItem } from './components/select-buttons/select-buttons.component';
import { EAboutMeOnboarding } from 'desiren-core-lib/lib/enums/onboarding/about-me.enum';
import { MyTypeOnboarding } from 'desiren-core-lib/lib/enums/onboarding/my-type.enum';
import { EPersonalOnboarding } from 'desiren-core-lib/lib/enums/onboarding/personal.enum';
import { EGeneralOnboarding } from 'desiren-core-lib/lib/enums/onboarding/general.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, take } from 'rxjs';
import {EHairColors} from "desiren-core-lib/lib/enums/hair-colors.enum";
import {EEyeColors} from "desiren-core-lib/lib/enums/eye-colors.enum";
import {EBodyTypes} from "desiren-core-lib/lib/enums/body-types.enum";
import {ESkinTone} from "desiren-core-lib/lib/enums/skin-tone.enum";
import {EHairLength} from "desiren-core-lib/lib/enums/hair-length.enum";
import {EHairStyle} from "desiren-core-lib/lib/enums/hair-style.enum";
import {EGender} from "desiren-core-lib/lib/enums/gender.enum";
import {ESize} from "desiren-core-lib/lib/enums/size.enum";
import {ENsfw} from "desiren-core-lib/lib/enums/nsfw.enum";
import {ESfw} from "desiren-core-lib/lib/enums/sfw.enum";

@Injectable({
  providedIn: 'root'
})
export class CreatorOnboardingService {
  private gateUrl = environment.apiUrl + '/onboarding/additional';
  public isNextStepAvailable = new BehaviorSubject<boolean>(false);
  public isNextStepAvailable$ = this.isNextStepAvailable.asObservable();

  constructor(private http: HttpClient) { }

  public getQuestionsList(): SelectableItem[] {
    const aboutMeArray = enumToObjectArray(EAboutMeOnboarding, EGeneralOnboarding.ABOUT_ME);
    const myTypeArray = enumToObjectArray(MyTypeOnboarding, EGeneralOnboarding.MY_TYPE);
    const personalOnboardingArray = enumToObjectArray(EPersonalOnboarding, EGeneralOnboarding.GETTING_PERSONAL);
    return [...aboutMeArray, ...myTypeArray, ...personalOnboardingArray];
  }

  public getContentList() {
    return selectItemsList;
  }

  public saveOnboardingData(body): Observable<any> {
    return this.http.put<any>(`${this.gateUrl}`, body , { withCredentials: true }).pipe(take(1));
  }
}

function enumToObjectArray(enumObj: any, enumName: string) {
  return Object.keys(enumObj).map((key) => {
    return {
      label: key,
      isSelected: false,
      categoryName: enumName
    };
  });
}

const selectItemsList = {
  contentList : [
    {
      label: ENsfw.COSPLAY,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'cosplay',
    },
    {
      label: ENsfw.DIRTY_TALK,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'sex',
    },
    {
      label: ENsfw.ANAL,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'anal',
    },
    {
      label: ENsfw.TOYS,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'dildo',
    },
    {
      label: ENsfw.ROLE_PLAY,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'roleplay',
    },
    {
      label: ENsfw.BDSM,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'bdsm',
    },
    {
      label: ENsfw.LATEX,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'latex',
    },
    {
      label: ENsfw.GAGGING,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'sex_doll',
    },
    {
      label: ENsfw.EXHIBITIONISM,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'exhibitionism',
    },
    {
      label: ENsfw.SOLO,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'couples',
    },
    {
      label: ENsfw.COUPLES,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'couples',
    },
    {
      label: ENsfw.DOUBLE,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'couples',
    },
    {
      label: ENsfw.GROUP,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'couples',
    },
    {
      label: ENsfw.FMM,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'gender',
    },
    {
      label: ENsfw.FFM,
      isSelected: false,
      categoryName: 'NSFW',
      icon: 'gender',
    },
    {
      label: ESfw.COSPLAY,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.ASMR,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'gender',
    },
    {
      label: ESfw.SPORTS,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.VLOGS,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.DANCING,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.COOKING_SHOWS,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.FEET,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.GAMING,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.HORSES,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },
    {
      label: ESfw.RACING,
      isSelected: false,
      categoryName: 'SFW',
      icon: 'cosplay',
    },


  ],
  heirColorList : Object.keys(EHairColors),
  eyeStyleList : Object.keys(EEyeColors),
  bodyTypeList : Object.keys(EBodyTypes),
  skinToneList : Object.keys(ESkinTone),
  hairLengthList : Object.keys(EHairLength),
  hairStyleList : Object.keys(EHairStyle),
  genderList : Object.keys(EGender),
  buttList : Object.keys(ESize),
  breastList : Object.keys(ESize),
  penisList : Object.keys(ESize),
}
