import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-body-options',
  standalone: true,
  imports: [NgIf, IconsComponent, ReactiveFormsModule, TranslocoModule],
  templateUrl: './body-options.component.html',
  styleUrl: './body-options.component.scss',
})
export class BodyOptionsComponent implements OnChanges {
  public form!: FormGroup;
  @Input() bodyOptions: { tattoos: boolean; piercing: boolean };
  @Input() isFunFilter: boolean = false;
  @Output() bodyOptionsEvent: EventEmitter<{ tattoos: boolean; piercing: boolean }> = new EventEmitter()
  constructor(private formBuilder: FormBuilder) {}
  ngOnChanges(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      tattoos: [this.bodyOptions.tattoos || false],
      piercing: [this.bodyOptions.piercing || false],
    });
    this.form.valueChanges.subscribe((data) =>
      this.bodyOptionsEvent.emit(data)
    );
  }
}
