<ng-container *transloco="let t;">
  <div class="selectable-list" #listContainer>
    <button *ngFor="let item of items"
     type="button"
     class="selectable-item"
     (click)="toggleSelection(item)"
     [ngClass]="{ selected: item.isSelected }">
      <app-icons *ngIf="item.icon" [name]="item.icon" [width]="20" [height]="20" [fillColor]="isFunFilter && item.isSelected ? '#ffffff' : '#6A49FA' "></app-icons>
      <span class="title" *ngIf="isFunFilter">{{ t(item.categoryName + '_' + item.label) }}</span>
      <span class="title" *ngIf="!isFunFilter">{{ t(item.categoryName + '_' + item.label) | underscoreToSpace }}</span>
      <app-icons *ngIf="!isFunFilter" name="non_read" [width]="24" [height]="24" [fillColor]="item.isSelected ? '#6A49FA' : 'transparent'"> </app-icons>
    </button>
  </div>
</ng-container>
