"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENsfw = void 0;
var ENsfw;
(function (ENsfw) {
    ENsfw["COSPLAY"] = "COSPLAY";
    ENsfw["DIRTY_TALK"] = "DIRTY_TALK";
    ENsfw["ANAL"] = "ANAL";
    ENsfw["TOYS"] = "TOYS";
    ENsfw["ROLE_PLAY"] = "ROLE_PLAY";
    ENsfw["BDSM"] = "BDSM";
    ENsfw["LATEX"] = "LATEX";
    ENsfw["GAGGING"] = "GAGGING";
    ENsfw["EXHIBITIONISM"] = "EXHIBITIONISM";
    ENsfw["SOLO"] = "SOLO";
    ENsfw["COUPLES"] = "COUPLES";
    ENsfw["DOUBLE"] = "DOUBLE";
    ENsfw["GROUP"] = "GROUP";
    ENsfw["FMM"] = "FMM";
    ENsfw["FFM"] = "FFM";
})(ENsfw || (exports.ENsfw = ENsfw = {}));
