"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EHairColors = void 0;
var EHairColors;
(function (EHairColors) {
    EHairColors["BLACK"] = "BLACK";
    EHairColors["BROWN"] = "BROWN";
    EHairColors["BLONDE"] = "BLONDE";
    EHairColors["RED"] = "RED";
    EHairColors["WHITE"] = "WHITE";
    EHairColors["BLUE"] = "BLUE";
    EHairColors["GREEN"] = "GREEN";
    EHairColors["PINK"] = "PINK";
    EHairColors["PURPLE"] = "PURPLE";
    EHairColors["SILVER"] = "SILVER";
    EHairColors["PASTEL"] = "PASTEL";
    EHairColors["OMBRE"] = "OMBRE";
    EHairColors["RAINBOW"] = "RAINBOW"; // multiple colors combined
})(EHairColors || (exports.EHairColors = EHairColors = {}));
