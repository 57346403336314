"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EEyeColors = void 0;
var EEyeColors;
(function (EEyeColors) {
    EEyeColors["BROWN"] = "BROWN";
    EEyeColors["BLUE"] = "BLUE";
    EEyeColors["GREEN"] = "GREEN";
    EEyeColors["HAZEL"] = "HAZEL";
    EEyeColors["AMBER"] = "AMBER";
    EEyeColors["GRAY"] = "GRAY";
    EEyeColors["COMBINE"] = "COMBINE";
})(EEyeColors || (exports.EEyeColors = EEyeColors = {}));
