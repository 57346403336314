import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {TranslocoModule} from "@ngneat/transloco";
import { UnderscoreToSpacePipe } from 'src/app/pipes/underscore-to-space.pipe';

export interface FilterItem  {
  label: string,
  value: string,
  count: number,
  categoryName: string,
  icon?: string
}

@Component({
  selector: 'app-filter-buttons',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    UnderscoreToSpacePipe
  ],
  templateUrl: './filter-buttons.component.html',
  styleUrl: './filter-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterButtonsComponent implements OnChanges{
  @Input() filterItems: FilterItem[] = [];
  @Input() filter: string = null;
  @Output() selectedFilter = new EventEmitter<FilterItem>();

  constructor(
    private crd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
      this.filter = this.defaultFilterValue;
  }

  setFilterString(item: FilterItem) {
    this.filter = item.value;
    this.selectedFilter.emit(item);
  }

  get defaultFilterValue() {
    return this.filterItems ? this.filterItems[0]?.value : null;
  }
}
