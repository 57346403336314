"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPersonalOnboarding = void 0;
var EPersonalOnboarding;
(function (EPersonalOnboarding) {
    EPersonalOnboarding["THOUGHTS"] = "THOUGHTS";
    EPersonalOnboarding["FANTASY"] = "FANTASY";
    EPersonalOnboarding["ROLEPLAY"] = "ROLEPLAY";
    EPersonalOnboarding["ADVENTUROUS_PLACE"] = "ADVENTUROUS_PLACE";
    EPersonalOnboarding["FETISHES"] = "FETISHES";
    EPersonalOnboarding["LINGERIE"] = "LINGERIE";
    EPersonalOnboarding["DOMINANT_OR_SUBMISSIVE"] = "DOMINANT_OR_SUBMISSIVE";
    EPersonalOnboarding["BDSM"] = "BDSM";
    EPersonalOnboarding["BIGGEST_TURN_ON"] = "BIGGEST_TURN_ON";
    EPersonalOnboarding["CUSTOM_CONTENT"] = "CUSTOM_CONTENT";
    EPersonalOnboarding["NAUGHTIEST"] = "NAUGHTIEST";
    EPersonalOnboarding["TOYS"] = "TOYS";
    EPersonalOnboarding["PERFECT_INTIMATE"] = "PERFECT_INTIMATE";
    EPersonalOnboarding["TEASE"] = "TEASE";
    EPersonalOnboarding["DIFFERENT_PERSONAS"] = "DIFFERENT_PERSONAS";
    EPersonalOnboarding["DARING_OUTFIT"] = "DARING_OUTFIT";
    EPersonalOnboarding["SECRET_FANTASIE"] = "SECRET_FANTASIE";
    EPersonalOnboarding["DIRTY_TALK"] = "DIRTY_TALK";
    EPersonalOnboarding["STARS"] = "STARS";
    EPersonalOnboarding["VOYEURISM"] = "VOYEURISM";
    EPersonalOnboarding["INTIMATE_REQUEST"] = "INTIMATE_REQUEST"; // What’s the most intimate request you’ve received from a fan?
})(EPersonalOnboarding || (exports.EPersonalOnboarding = EPersonalOnboarding = {}));
