import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss'
})
export class LogoComponent {
  @Input() iconColor: string = '6A49FA';
  @Input() textColor: string = '131315';
  @Input() width: string = '130';
  @Input() height?: string;
}
