"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAboutMeOnboarding = void 0;
var EAboutMeOnboarding;
(function (EAboutMeOnboarding) {
    EAboutMeOnboarding["HOBBIES"] = "HOBBIES";
    EAboutMeOnboarding["ENJOY"] = "ENJOY";
    EAboutMeOnboarding["FAVORITE"] = "FAVORITE";
    EAboutMeOnboarding["PETS"] = "PETS";
    EAboutMeOnboarding["SURPRISE"] = "SURPRISE";
    EAboutMeOnboarding["MUSIC"] = "MUSIC";
    EAboutMeOnboarding["TRAVEL"] = "TRAVEL";
    EAboutMeOnboarding["GOALS"] = "GOALS";
    EAboutMeOnboarding["RELATIONSHIP"] = "RELATIONSHIP";
    EAboutMeOnboarding["HABITS"] = "HABITS"; // Do you have any personal rituals or habits that you follow?
})(EAboutMeOnboarding || (exports.EAboutMeOnboarding = EAboutMeOnboarding = {}));
