import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { TranslocoModule } from '@ngneat/transloco';
import { UnderscoreToSpacePipe } from 'src/app/pipes/underscore-to-space.pipe';

export interface SelectableItem {
  label: string;
  isSelected: boolean;
  categoryName: string;
  icon?: string;
}

@Component({
  selector: 'app-select-buttons',
  standalone: true,
  imports: [
    CommonModule,
    IconsComponent,
    TranslocoModule,
    UnderscoreToSpacePipe,
  ],
  templateUrl: './select-buttons.component.html',
  styleUrl: './select-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonsComponent implements OnChanges {
  @Input() isFunFilter: boolean = false;
  @Input() items: SelectableItem[] = [];
  @Input() maxSelection: number = 99;
  @Input() selectedItemsAmount: number;
  @Output() onSelectItemHandler = new EventEmitter<SelectableItem[]>();
  @ViewChild('listContainer') listContainer!: ElementRef;
  constructor(private toastr: ToastrService) {}

  ngOnChanges(changes): void {
    if (changes.items) {
      if (this.listContainer) {
        const listContainerElement = this.listContainer.nativeElement;
        listContainerElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }

  public toggleSelection(item: SelectableItem): void {
    const selectedItemsCount = this.items.filter((i) => i.isSelected).length;

    if (this.selectedItemsAmount < this.maxSelection) {
      item.isSelected = !item.isSelected;
      this.emitSelectedItems();
    } else {
      console.warn(`Maximum of ${this.maxSelection} items can be selected.`);
      this.toastr.error(
        `Maximum of ${this.maxSelection} items can be selected.`
      );
    }
  }

  public emitSelectedItems(): void {
    const selectedItems = this.items.filter((item) => item.isSelected);
    this.onSelectItemHandler.emit(selectedItems);
  }
}
