<ng-container *transloco="let t;">
  <div class="wrapper">
    <ng-container *ngIf="!isFunFilter">
      <app-icons
      [name]="'tattoo'"
      height="100"
      width="100"
      [fillColor]="'#6A49FA'"
      ></app-icons>
      <h3>{{t('Something_interesting')}}</h3>
    </ng-container>

    <form [formGroup]="form">
      <div class="country-form input-wrapper">
        <div class="toggle-wrapper">
          <span class="input-label text-bold">{{t('Tattoos')}}</span>
          <label class="slide-toggle">
            <input type="checkbox" formControlName="tattoos" />
            <span class="slider"></span>
          </label>
        </div>
        <div class="toggle-wrapper">
          <span class="input-label text-bold">{{t('Piercing')}}</span>
          <label class="slide-toggle">
            <input type="checkbox" formControlName="piercing" />
            <span class="slider"></span>
          </label>
        </div>
      </div>
  </form>
  </div>
</ng-container>
